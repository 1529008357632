import React from 'react';
import { withAdminLayout } from '../../layout';
import { withAppContext } from '../../components/withAppContext';
import { FormattedMessage } from 'react-intl';

const Admin = props => {
  return <div className="admin"><FormattedMessage id="dashboard.Admin Area" /></div>;
};

const customProps = {
  withAuth: true,
};

export default withAdminLayout(customProps)(withAppContext(Admin));
